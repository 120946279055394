import '../less/index.less';

(function () {

    'use strict';

    var AutoTechnik = {};
    AutoTechnik.historyPushStateCount = 0;
    AutoTechnik.jqXHR = null;

    AutoTechnik.initHeaderMenu = function () {
        var headerMenuButton = $('.headerMenuButton');
        var headerMenu = $('.headerMenu');
        headerMenuButton.on('click tap', function () {
            if (headerMenuButton.hasClass('active')) {
                headerMenuButton.removeClass('active');
                headerMenu.removeClass('active');
            } else {
                headerMenuButton.addClass('active');
                headerMenu.addClass('active');
            }
        });
    };

    AutoTechnik.initialiseHeaderSearch = function () {
        var headerSearchInput = $('.header-search input');
        var headerSearchButton = $('.header-search button');
        headerSearchInput
            .focus(function () {
                headerSearchButton.addClass('focus');
            })
            .blur(function () {
                headerSearchButton.removeClass('focus');
            });
    };

    AutoTechnik.initializeNewsletter = function () {
        var newsletterInput = $('.newsletter input');
        var newsletterButton = $('.newsletter button');
        newsletterInput
            .focus(function () {
                newsletterButton.addClass('focus');
            })
            .blur(function () {
                newsletterButton.removeClass('focus');
            });
    };


    AutoTechnik.initialiseHeaderLinks = function () {
        var anchors = $('.header-links-item-with-menu > a');
        var menus = $('.header-links-menu');
        var backdrop;

        anchors.on('click tap', function (e) {
            e.preventDefault();

            var anchor = $(this);
            var menu = $('.header-links-menu', anchor.parent());
            var arrowLeft = anchor.position().left + (anchor.width() / 2);
            menus.hide();
            menu.show();
            console.log(arrowLeft);
            $('.arrow', menu).css('left', arrowLeft + 'px');
            backdrop = $('<div class="backdrop"></div>');
            backdrop
                .insertAfter(menu)
                .on('click tap', function (e) {
                    e.preventDefault();
                    backdrop.remove();
                    menus.hide();
                });
        });
    };


    AutoTechnik.initVehicleManufacturer = function () {
        var vehicleModels = $('.vehicle-model');
        if (!vehicleModels.length)
            return;


        var anchors = $('a', vehicleModels);
        var backdrop;


        anchors.on('click tap', function (e) {
            e.preventDefault();

            var anchor = $(this);
            var vehicleModel = $(this).parent();
            var col = vehicleModel.parent();
            var dropdown = $('<div class=\'vehicle-motorizations\'>' +
                '<div class=\'arrow\'></div>' +
                '<button class=\'close\'>&times;</button>' +
                '<div class=\'content\'><div class=\'ajax-loader\'>' + km_spinner.html + '<div class=\'text\'>' + anchor.attr('data-ajax-loader-models-text') + '</div></div></div>' +
                '</div>');
            backdrop = $('<div class="backdrop"></div>');

            col.parent().append(dropdown);

            dropdown.css('top', (col.position().top + vehicleModel.position().top + anchor.outerHeight()) + 'px');
            $('.arrow', dropdown).css('left', col.position().left + 'px');
            anchor.addClass('active-dropdown');

            backdrop
                .insertAfter(dropdown)
                .on('click', function (e) {
                    e.preventDefault();
                    anchor.removeClass('active-dropdown');
                    dropdown.remove();
                    backdrop.remove();
                });

            $('.close').on('click tap', function (e) {
                e.preventDefault();
                anchor.removeClass('active-dropdown');
                dropdown.remove();
                backdrop.remove();
            });

            $.get(this.href, function (data) {
                $('.content', dropdown).html(data);
                $('.js-model-fullname', dropdown).html(anchor.attr('data-model-fullname'));
                $('.vehicle-motorization-item', dropdown).on('click tap', function (e) {
                    e.preventDefault();
                    //KvikyMart.modal.showLoader();
                    $('.content', dropdown).html('<div class=\'ajax-loader\'>' + km_spinner.html + '<div class=\'text\'>' + anchor.attr('data-ajax-loader-car-text') + '</div>');
                    var href = this.href;
                    setTimeout(function () {
                        document.location.href = href;
                    }, 200);
                });
            }, 'html');
        });
    };


    AutoTechnik.initVehicle = function () {
        var categoriesNav = $('.categories-nav');
        if (!categoriesNav.length)
            return;

        var tpc = $('#tecdoc-page-content');
        if (!tpc.length) {
            return;
        }


        // označení aktuální položky menu
        var agnid = KMPayload.tecdocAssemblyGroupNodeId;
        if (agnid) {
            var a = AutoTechnik.highlightCategoriesNavLink('km-assembly-groups-tree-node-' + agnid);
            var listItems = a.parentsUntil('.categories-nav', '.node');
            listItems.addClass('in');
        } else
            AutoTechnik.highlightCategoriesNavLink('nav-vehicle-info');

        // browser's history back button
        window.onpopstate = function (e) {
            if (e.state) {
                window.location = e.state.href;
            } else {
                if (AutoTechnik.historyPushStateCount > 0)
                    window.location = window.location.href;
            }
        };
    };



    AutoTechnik.highlightCategoriesNavLink = function (id) {
        // odznačení aktivní položky v menu
        $('.categories-nav a.current').removeClass('current');
        if (id == null)
            return;

        // označení aktivní kategorie v menu
        var a = $('.categories-nav #' + id);
        a.addClass('current');

        // otevření podmenu, pokud je odkaz level-1 a není už otevřené
        var listItem = a.closest('.item');
        if (!listItem.is('.in'))
            $('> .opener', listItem).click();

        return a;
    };

    AutoTechnik.enhanceVinInputsAtDeliveryPaymentAddress = function () {
        var vinField1Input = document.getElementById('frm-deliveryPaymentAddress-addressForm-customFieldsRawValues-znacka_typ_motor_a_rok_vyroby');
        var vinField2Input = document.getElementById('frm-deliveryPaymentAddress-addressForm-customFieldsRawValues-vin_cislo_karoserie');

        var setVinFieldRules = function (enabled) {
            vinField1Input.required = enabled;
            vinField2Input.required = enabled;
            if (enabled === true) {
                vinField1Input.setAttribute('data-nette-rules', '[{"op":":filled","msg":"Zadajte prosím Značku, typ, motor a rok výroby nebo VIN číslo karosérie níže."}]');
                vinField2Input.setAttribute('data-nette-rules', '[{"op":":filled","msg":"Zadajte prosím VIN číslo karosérie nebo Značku, typ, motor a rok výroby výše."}]');
            } else {
                vinField1Input.removeAttribute('data-nette-rules');
                vinField2Input.removeAttribute('data-nette-rules');
            }
        };

        if (vinField1Input && vinField2Input) {
            setVinFieldRules(true);

            var smallElement = document.createElement('small');
            smallElement.innerHTML = 'VIN číslo služí na overenie správnosti objednaných dielov – odporúčame ho uviesť.';
            vinField2Input.parentNode.appendChild(smallElement);


            var isAtLeastOneVinFieldFilled = function () {
                if ((vinField1Input.value !== '') || (vinField2Input.value !== '')) {
                    setVinFieldRules(false);
                } else {
                    setVinFieldRules(true);
                }
            };

            vinField1Input.addEventListener('input', function () {
                isAtLeastOneVinFieldFilled();
            });
            vinField2Input.addEventListener('input', function () {
                isAtLeastOneVinFieldFilled();
            });
        }
    };

    AutoTechnik.initCategoriesNavOpener = function () {
        var categoriesNavOpener = $('.categories-nav-opener');
        var sidebarNav = $('.categories-nav');
        categoriesNavOpener.click('click tap', function () {
            if (categoriesNavOpener.hasClass('active')) {
                categoriesNavOpener.removeClass('active');
                sidebarNav.removeClass('active');
            } else {
                categoriesNavOpener.addClass('active');
                sidebarNav.addClass('active');
            }
        });
    };

    AutoTechnik.initMissingPriceToFreeDelivery = function () {
        var missingPriceToFreeDeliveryDiv = $('.missingPriceToFreeDelivery');
        var missingPriceToFreeDeliveryPriceSpan = $('.missingPriceToFreeDelivery__price');

        $(document).on('KM.DeliveryPaymentAddress.deliveryMethodChange', function (event, dataObject) {
            if (dataObject.hasOwnProperty('kvikymartMisssingPriceToFree') && dataObject.checked === true) {
                missingPriceToFreeDeliveryDiv.addClass('missingPriceToFreeDelivery--active');
                missingPriceToFreeDeliveryPriceSpan.html(dataObject.kvikymartMisssingPriceToFree);
            } else {
                missingPriceToFreeDeliveryDiv.removeClass('missingPriceToFreeDelivery--active');
            }
        });
    };

    AutoTechnik.initHomepageBonuses = function () {
        var $homepageBanners = $('.homePageParts .pagePart--imageSlider');
        if (!$homepageBanners.length)
            return;

        // var $btns = $('.btn', $homepageBanners);
        // if (!$btns.length)
        //     return;

        var $banners = $('.imageSliderPagePart__imageWrapper', $homepageBanners);
        const buttons = [];
        const buttonsWrapperElement = document.createElement('div');
        buttonsWrapperElement.className = 'imageSliderPagePartButtons';

        for (let i = 0; i < $banners.length; i++) {
            const banner = $banners.get(i);
            banner.className += ` imageSliderPagePart__imageWrapper--${i}`;
            // $(banner).click(() => {
            //     const img = banner.getElementsByClassName('imageSliderPagePart__image')[0];
            //     km_ga('send', 'event', 'Banner', 'HP/Big', `HP/Big – ${img.getAttribute('alt')}`);
            // });
            const button = document.createElement('button');
            button.className = `btn btn-sm imageSliderPagePartButtons__button imageSliderPagePartButtons__button--${i}`;

            if (i === 0) {
                banner.className += ` imageSliderPagePart__imageWrapper--active`;
                button.className += ` imageSliderPagePartButtons__button--active`;
            }

            button.setAttribute('data-id', i.toString());
            button.textContent = i + 1;
            buttons.push(button);
            buttonsWrapperElement.appendChild(button);
        }

        $homepageBanners[0].appendChild(buttonsWrapperElement);

        var switchById = function (id) {
            $(buttons).removeClass('imageSliderPagePartButtons__button--active');
            $('.imageSliderPagePartButtons__button--' + id).addClass('imageSliderPagePartButtons__button--active');
            var $banner = $('.imageSliderPagePart__imageWrapper--' + id);
            $banners.not($banner).removeClass('imageSliderPagePart__imageWrapper--active');
            $banner.addClass('imageSliderPagePart__imageWrapper--active');
        };

        var switchNext = function () {
            if ($homepageBanners.is(':hover'))
                return;

            var $activeBtn = $('.imageSliderPagePartButtons__button--active', $homepageBanners);
            var $nextBtn = $activeBtn.next();

            if (!$nextBtn.length)
                $nextBtn = $('.imageSliderPagePartButtons__button--0', $homepageBanners);

            var id = $nextBtn.attr('data-id');
            switchById(id);
        };

        $(buttons).on('click tap', function () {
            var $this = $(this);
            if ($this.hasClass('imageSliderPagePartButtons__button--active'))
                return;

            var id = $this.attr('data-id');
            switchById(id);
        });

        const intervalID = window.setInterval(switchNext, 6000);
    };

    $(function () {
        AutoTechnik.initHomepageBonuses();
        AutoTechnik.initMissingPriceToFreeDelivery();
        AutoTechnik.initCategoriesNavOpener();
        AutoTechnik.initHeaderMenu();
        AutoTechnik.initialiseHeaderSearch();
        AutoTechnik.initializeNewsletter();
        AutoTechnik.initialiseHeaderLinks();
        AutoTechnik.initVehicleManufacturer();
        AutoTechnik.initVehicle();
        AutoTechnik.enhanceVinInputsAtDeliveryPaymentAddress();
    });


})();